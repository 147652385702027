<!--  -->
<template>
    <div class="curriculum flex-column-between" id="pdfHtml" ref="printHtml">
        <entrance @renewal="renewal" @changTip="changTip"></entrance>
        <div class="iput flex-align-center">作品集</div>
        <div class="operationBtn flex-align" v-if="switchIndex == 0">
            <div class="searchInput flex-align">
                <el-input v-model="input" placeholder="请输入关键词搜索"></el-input>
                <el-button @click="search">搜索</el-button>
            </div>
            <div class="excellentWorks flex-align" @click="goCurriculum">
                查看优秀作品
                <el-rate v-model="star" disabled :max="1" style="height: auto;">
                </el-rate>
            </div>
        </div>
        <div class="Screening flex-align-between" v-else>
            <div class="searchInput flex-align">
                <el-input v-model="input" placeholder="请输入关键词搜索"></el-input>
                <el-button @click="search">搜索</el-button>
            </div>
        </div>
        <div class="switchType">
            <div class="flex-align-center" :class="{ btncolor: switchIndex == index }"
                v-for="(item, index) in switchTypeList" :key="index" @click="switchclick(index)">
                {{ item }}
            </div>
        </div>
        <div class="main">
            <ul v-infinite-scroll="load" infinite-scroll-distance='100'>
                <li v-for="(item, index) in Object.keys(worksList).sort().reverse()" :key="index">
                    <div class="courseDate">
                        <span>{{ item }}</span>
                        <span>{{ weeks[new Date(item).getDay()] }}</span>
                    </div>
                    <el-timeline :simple="false">
                        <el-timeline-item v-for="(itemSon, indexSon) in worksList[item]" :key="indexSon"
                            :timestamp="renderTime(itemSon.startTime || itemSon.uploadTime)" placement="top">
                            <!-- itemSon:{{itemSon}}
                            <br>
                            indexSon:{{indexSon}} -->
                            <div class="courseware flex-align-around"
                                :class="{ coursewarHover: addHover && qrcodeIndexSon == indexSon && qrcodeIndex == index, }"
                                @click.self="options ? goWord(itemSon.lessonId, itemSon.classId, itemSon.wordId, itemSon.wordName, itemSon.className, item) : goWordPersonal(itemSon.workName)"
                                @mouseenter.self="enter(indexSon, index)" @mouseleave.self="leave(indexSon, index)">
                                <div class="type flex-align-between"
                                    @click="options ? goWord(itemSon.lessonId, itemSon.classId, itemSon.wordId, itemSon.wordName, itemSon.className, item) : goWordPersonal(itemSon.workName)">
                                    <span v-if="options">{{ itemSon.className }}【{{ itemSon.wordName
                                    }}】的作品{{ itemSon.wordId ? '' : ' [只读]' }}</span>
                                    <span v-else>【{{ itemSon.workName }}】的作品</span>
                                </div>
                                <div v-show="false" class="UploadStatus flex-align-between"
                                    @click="options ? goWord(itemSon.lessonId, itemSon.classId, itemSon.wordId, itemSon.wordName, itemSon.className, item) : goWordPersonal(itemSon.workName)">
                                    <img :src="itemSon.uploadCount == 0 ? UploadStatusImg1 : UploadStatusImg2" />
                                    <span>{{ itemSon.uploadCount == 0 ? "未上传" : "已上传" }}</span>
                                </div>
                                <div class="worksNum flex-align-between"
                                    @click="options ? goWord(itemSon.lessonId, itemSon.classId, itemSon.wordId, itemSon.wordName, itemSon.className, item) : goWordPersonal(itemSon.workName)">
                                    {{ itemSon.uploadCount || itemSon.count }}份作品
                                </div>
                                <div class="upload flex-align-between" v-if="false"
                                    :class="`${index + 'uploadTip' + indexSon}`"
                                    @click="qrcodeUpload(`${index + 'uploadTip' + indexSon}`, itemSon.uploadCount, itemSon.lessonId, itemSon.mpCodePath, itemSon.classId, itemSon.wordId, itemSon.wordName)">
                                    <img src="@/assets/showreel/uploading.png" />
                                    <span class="uploadText">上传</span>
                                    <div class="uploadTipQr flex-column-center"
                                        v-if="qrstatus && identification == `${index + 'uploadTip' + indexSon}`">
                                        <div class="container flex-align-center">
                                            <img :src="itemSon.mpCodePath" />
                                        </div>
                                        <span>微信扫码</span>
                                        <span>上传本课作品</span>
                                    </div>
                                </div>
                            </div>
                            <div class="delSet" @click="checkedBox(options ? itemSon.lessonId : itemSon.workName)"
                                v-if="!DleBtn">
                                <img
                                    :src="[arr.includes(itemSon.lessonId) || arr.includes(itemSon.workName) ? require('@/assets/showreel/delB.png') : require('@/assets/showreel/delA.png'),]" />
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </li>
            </ul>
            <div class="placeholder flex-column-center" v-if="Object.keys(worksList).sort().reverse().length == 0">
                <div></div>
                <span>暂无内容</span>
            </div>
        </div>
        <div class="explain flex-align-center">
            *
            更新说明：参加活动提交的作品，将在每次任务结束后24小时内进行更新。敬请期待
        </div>
        <div class="base"></div>
        <subscribe :dialogVisible="centerDialogVisible2" @clear="clear"></subscribe>
    </div>
</template>

<script>
import entrance from "../../Common_components/head/entrance";
import subscribe from "../../Common_components/Popup/subscribe.vue";
import {
    mapState,
    mapMutations
} from "vuex";
export default {
    data() {
        return {
            changeable: true,
            options: true,
            worksList: {},
            weeks: [
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六",
            ],
            qrcodeIndex: null,
            qrcodeIndexSon: null,
            addHover: false,
            UploadStatusImg1: require("../../assets/showreel/wait.png"),
            UploadStatusImg2: require("../../assets/showreel/accomplish.png"),
            qrstatus: false,
            identification: null,
            arr: [],
            DleBtn: true,
            centerDialogVisible2: false,
            switchTypeList: ["学生作品", "我的作品"],
            switchIndex: 0,
            input: "",
            page: 0,
            star: 1
        };
    },
    components: {
        entrance,
        subscribe,
    },
    watch: {
        input(n, l) {
            this.search()
        }
    },
    //方法
    methods: {
        ...mapMutations(["hint_data"]),
        renewal(value) {
            //页面销毁时关闭长连接
            // this.changeable = value;
            // this.imgArr = [];
            // this.throttle = 0;
            // this.viewerVal = 0;
            // this.resource = false;
            // sessionStorage.removeItem("Flush");
            // if (value && this.websock) {
            //       this.websocketclose();
            //       this.websock.send(this.closeJunction);
            // }
        },
        clear(bool) {
            this.centerDialogVisible2 = bool;
        },
        changTip(value) {
            if (value) {
                this.hint_data("暂无活动");
                this.centerDialogVisible2 = true;
            }
        },
        //学生作品集
        stuWorks() {
            this.options = true;
            this.arr = [];
            // console.log('学生作品集')
            this.getTeacherWorkHistory();
            // sessionStorage.setItem("Flush", JSON.stringify(this.wordData));
        },
        // 无线滚动方法
        load() {
            // console.log('触发无限滚动方法');
            if (this.switchIndex == 1) {

            } else {
                this.page += 1;
                this.stuWorks()
            }
        },
        //获取课时列表数据 --学生作品集合
        async getTeacherWorkHistory() {
            let data = {
                page: this.page,
                limit: 20
            }
            let resData = await this.$Api.Myclass.getTeacherWorkHistory(data);
            // console.log('resData:', resData)
            this.worksList = {
                ...this.worksList,
                ...resData.data.list[0]
            };
        },
        //我的作品集
        personalWorks() {
            this.options = false;
            this.DleBtn = true;
            this.arr = [];
            this.getQQTestList();
        },
        //我的作品集合
        async getQQTestList() {
            let resData = await this.$Api.Myclass.getQQTestList();
            console.log('我的作品集--', resData)
            this.worksList = resData.data;
        },
        renderTime(date) {
            if (this.options == true) {
                var dateee = new Date(date).toJSON();
                let data = new Date(+new Date(dateee) + 8 * 3600 * 1000)
                    .toISOString()
                    .replace(/T/g, " ")
                    .replace(/\.[\d]{3}Z/, "");
                data = data.slice(10, 19);
                return data;
            } else {
                let data = date.slice(10, 19);
                return data;
            }
        },
        //高亮
        enter(indexSon, index) {
            this.qrcodeIndexSon = indexSon;
            this.qrcodeIndex = index;
            this.addHover = true;
        },
        leave(indexSon, index, lessonId) {
            this.qrcodeIndexSon = indexSon;
            this.qrcodeIndex = index;
            this.addHover = false;
        },
        //跳转到学生作品集合
        goWord(lessonId, classId, wordId, wordName, className,endTime) {
            if (!wordId) {
                this.$router.push({
                    path: `/syncProjection?lessonId=${lessonId}&wordName=${wordName}&className=${className}&endTime=${endTime}`
                })
            } else {
                sessionStorage.setItem("type", "0");
                this.$router.push({
                    path: "/wordsEvaluation",
                    query: {
                        type: 0,
                        lessonId: lessonId,
                        classId: classId,
                        wordId: wordId,
                        wordName: wordName,
                    },
                });
            }

        },
        //跳转到我的作品
        goWordPersonal(lessonName) {
            sessionStorage.setItem("type", "1");
            this.$router.push({
                path: "/wordsEvaluation",
                query: {
                    type: 1,
                    wordName: lessonName,
                    switchIndex: this.switchIndex
                },
            });
        },
        qrcodeUpload(value, num, lessonId, mpCodePath, classId, wordId, wordName) {
            let that = this;
            if (num == 0 && mpCodePath == null) {
                this.goWord(lessonId, classId, wordId, wordName);
            } else if (mpCodePath != null && num == 0) {
                let img = new Image();
                img.src = mpCodePath;
                img.onload = function () {
                    that.identification = value;
                    that.qrstatus = true;
                };
                img.onerror = function () {
                    that.goWord(lessonId, classId, wordId, wordName);
                };
            } else if (mpCodePath == null && num != 0) {
                this.goWord(lessonId, classId, wordId, wordName);
            } else if (mpCodePath != null && num != 0) {
                let img = new Image();
                img.src = mpCodePath;
                img.onload = function () {
                    that.identification = value;
                    that.qrstatus = true;
                };
                img.onerror = function () {
                    that.goWord(lessonId, classId, wordId, wordName);
                };
            }
        },
        checkedBox(value) {
            if (this.arr.includes(value)) {
                this.arr = this.arr.filter(function (ele) {
                    return ele != value;
                });
            } else {
                this.arr.push(value);
            }
        },
        //删除选项
        showDleBtn() {
            this.DleBtn = false;
        },
        //取消按钮
        cancel() {
            this.DleBtn = true;
            this.arr = [];
        },
        //删除按钮
        del() {
            let _this = this;
            if (this.arr.length == 0) return;
            this.$confirm(
                "删除所选课程记录，同时所包含作品也将被清除，确定要删除吗？",
                "确认删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "MessClass",
                confirmButtonClass: "warningClass",
                center: true,
            }
            )
                .then(() => {
                    _this.options ?
                        _this.deleteTeacherWork(_this.arr) :
                        _this.deleteQQWork(_this.arr);
                })
                .catch(() => {
                    _this.arr = [];
                });
        },
        //删除时间轴学生作品列表
        async deleteTeacherWork(arr) {
            let data = {
                ids: arr.toString(),
            };
            let resData = await this.$Api.Myclass.deleteTeacherWork(data);
            if (resData.code == 200) {
                this.$message({
                    message: "删除成功",
                    center: true,
                    type: "success",
                });
                this.arr = [];
                this.getTeacherWorkHistory();
            }
        },
        //删除时间轴我的作品列表
        async deleteQQWork(arr) {
            let data = {
                ids: arr.toString(),
            };
            let resData = await this.$Api.Myclass.deleteQQWork(data);
            if (resData.code == 200) {
                this.$message({
                    message: "删除成功",
                    center: true,
                    type: "success",
                });
                this.arr = [];
                this.getQQTestList();
            }
        },
        switchclick(index) {
            this.switchIndex = index;
            this.input = '';
            this.worksList = {};
            this.page = 1;
            if (this.switchTypeList[this.switchIndex] == "学生作品") {
                this.stuWorks();
            } else {
                this.personalWorks();
                this.cancel();
            }
        },
        gosurfaceHome() {
            this.$router.push({
                name: "surfaceHome",
                query: {
                    type: "0",
                },
            });
        },
        // 搜索
        async search() {
            console.log(this.input)
            // if (!this.input.trim()) {
            //   this.hint_data("请输入关键词");
            //   this.centerDialogVisible2 = true;
            //   return
            // }
            let data = {
                keyWord: this.input
            }
            if (this.switchTypeList[this.switchIndex] == "学生作品") {
                this.options = true;
                this.arr = [];
                let resData = this.input ? await this.$Api.Myclass.getTeacherWorkHistory(data) : await this.$Api
                    .Myclass.getTeacherWorkHistory();
                this.worksList = resData.data;
            } else {
                this.options = false;
                this.DleBtn = true;
                this.arr = [];
                let resData = this.input ? await this.$Api.Myclass.getQQTestList(data) : this.$Api.Myclass
                    .getQQTestList();
                this.worksList = resData.data;
            }
        },
        // 查看优秀作品
        goCurriculum() {
            this.$router.push({
                name: "surfaceHome",
                query: {
                    type: "0",
                },
            });
        },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() { },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() { },
};
</script>
<style lang='less' scoped>
@import "./less/curriculum.less";
</style>